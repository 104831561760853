<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div style="padding: 50px; background-color: #ededed;">
      <a href="/reason-detail">
        <p style="font-size: 1.5rem; color: rgb(135 131 131);">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon> ย้อนกลับ
        </p>
      </a>
      <br />
      <div
        style="padding: 3rem;background-color: #fff;"
        class="app-content--inner"
      >
        <div class="row">
          <div class="col-12">
            <h3 class="mt-4 text-left" style="color: #000;">
              <b>{{lessonDetail.lesson_name}}</b>
            </h3>
            <h3 class="mt-4 text-left"><b>{{lessonDetail.lesson_detail}}</b></h3>
          </div>

          <br />
          <div class="col-12">
            <youtube
              video-id="h1gDNpT26G4"
              ref="youtube"
              @playing="playing"
              @paused="pause"
              style="width: 100%; height: 600px;"
            ></youtube>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #fcc820;
                text-decoration-thickness: 3px;
              "
            >
              <b>เอกสารประกอบการเรียน</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <img src="@/assets/img/document.png" style="width: 40px;" />
          </div>
          <div class="col-4">
            <p
              style="
              font-size: 1rem;
              color: #454545;
              "
            >
              <b
                >สื่อประกอบการเรียนบทที่ 1<br /><span style="font-size: 0.7rem;"
                  >PDF</span
                ></b
              >
            </p>
          </div>
          <div class="col-1">
            <img src="@/assets/img/document.png" style="width: 40px;" />
          </div>
          <div class="col-4">
            <p
              style="
              font-size: 1rem;
              color: #454545;
              "
            >
              <b
                >ภาพประกอบการเรียนบทที่ 1 <br /><span style="font-size: 0.7rem;"
                  >PDF</span
                ></b
              >
            </p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #fcc820;
                text-decoration-thickness: 3px;
              "
            >
              <b>แบบทดสอบ</b>
            </p>
          </div>
        </div>
        <div class="row" >
          <div class="col-12" style="margin-top: 20px;">
            <div>
              <button
                class="btn btn-lg btn-primary btn-block form-group"
                style="color: #fff;"
                :disabled="isShowTest"
                @click="onClickTest()"
              >
                <span>
                  <b>เริ่มทำแบบทดสอบ</b>
                </span>
              </button>
            <div class="text-center">
                <span  style="color: rgb(255 0 0);;">*กรุณาดูบทเรียนให้ครบ 1 นาที เพื่อเริ่มทำแบบทดสอบ</span>
          </div>
            </div>
          </div>
        </div>

        <!-- <youtube
        :video-id="videoId"
        ref="youtube"
        @playing="playing"
        @paused="pause"
      ></youtube>
      <button @click="playVideo">play</button> -->
      </div>
    </div>
    <do-test
      v-model="isShowTest"
      @delete:success="onDeleteSuccess"
      @delete:not="onDeleteNot"
    ></do-test>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import DoTest from "../../components/modal/DoTest";
// import VueYoutube from 'vue-youtube'
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";
import axios from "axios";

export default {
  components: {
    PageContent,
    DoTest
    // VueYoutube
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {
      videoId: "xgOSHgZw8yU",
      currentTime: 0,
      t: null,
      count: 60,
      isShowTest: false,
      lessonDetail: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onClickTest(){
//       let routeData = this.$router.resolve({name: `/test-question`, query: {testId: this.lessonDetail.testId}});
// window.open(routeData.href, '_blank');
      // this.$router.push(`/test-question?testId=${this.lessonDetail.testId}`);
      window.open(`http://e-learning-tutor.yuzudigital.com/test-question?testId=${this.lessonDetail.testId}`, '_blank');
      
    },
    async init() {
       console.log(this.$route.query.lessonId)
      var promise = await axios.get(`https://e-learnning-service-temp.yuzudigital.com/lessons/${this.$route.query.lessonId}`);
      console.log(promise.data.data);
      this.lessonDetail = promise.data.data;
    },
    playVideo() {
      this.player.playVideo();
      
    },
    countdown() {
      console.log(this.count)
      // starts countdown
      if (this.count === 0 && !this.isShowTest) {
        // time is up
        this.player.pauseVideo();
        this.isShowTest = true;
      } else {
        this.count--;
        this.t = setTimeout(this.countdown, 1000);
      }
    },
    playing() {
      console.log("we are watching!!!");
      this.countdown();
    },
    pause() {
      console.log("pause!!!");
      clearTimeout(this.t);
    },
    onDeleteSuccess() {

      this.isShowTest = true;
      console.log('Do!!')

      // let routeData = this.$router.resolve({name: '/do-test'});
      window.open('https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com/do-test', '_blank');
      // window.open('/')
    },
    onDeleteNot() {
      console.log('Not Do!!')
      // window.open('/')
      this.isShowTest = true;
      this.player.playVideo();
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
